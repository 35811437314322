import React from "react"

import Page from "components/Page"
import Container from "components/Container"

const PrivacyPolicy = () => {
  return (
    <Page showFooterCTA={false}>
      <Container>Privacy Policy (WIP)</Container>
    </Page>
  )
}

export default PrivacyPolicy
